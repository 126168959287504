export const WORDS = [
  'south',
  'korea',
  'seoul',
  'daegu',
  'busan',
  'jimin',
  'jhope',
  'mochi',
  'skool',
  'youth',
  'wings',
  'seven',
  'young',
  'never',
  'alone',
  'dream',
  'blood',
  'sweat',
  'tears',
  'awake',
  'rkive',
  'agust',
  'youth',
  'world',
  'light',
  'stars',
  'dance',
  'intro',
  'outro',
  'track',
  'start',
  'proof',
  'steel',
  'cryin',
  'heart',
  'beats',
  'video',
  'bombs',
  'train',
  'debut',
  'happy',
  'ruled',
  'album',
  'pills',
  'study',
  'music',
  'known',
  'promo',
  'video',
  'lyric',
  'idols',
  'skits',
  'where',
  'spine',
  'crush',
  'break',
  'theme',
  'sound',
  'remix',
  'hyung',
  'anger',
  'phile',
  'doing',
  'could',
  'grade',
  'sense',
  'angst',
  'songs',
  'scene',
  'title',
  'white',
  'black',
  'tight',
  'write',
  'peach',
  'night',
  'house',
  'cards',
  'cheer',
  'souls',
  'spoon',
  'stork',
  'bloom',
  'urban',
  'group',
  'chant',
  'party',
  'thank',
  'meets',
  'begin',
  'first',
  'wrong',
  'three',
  'think',
  'grows',
  'tempt',
  'worth',
  'women',
  'right',
  'girls',
  'today',
  'story',
  'close',
  'masks',
  'sands',
  'water',
  'piper',
  'speak',
  'fears',
  'lives',
  'faces',
  'rings',
  'leave',
  'crack',
  'truth',
  'magic',
  'vibes',
  'pedal',
  'drops',
  'vlive',
  'trade',
  'gonna',
  'focus',
  'super',
  'thing',
  'depth',
  'juice',
  'clock',
  'inner',
  'child',
  'swans',
  'disco',
  'drums',
  'steps',
  'smile',
  'mixed',
  'about',
  'abide',
  'genre',
  'turns',
  'voice',
  'synth',
  'style',
  'throw',
  'skill',
  'poems',
  'hater',
  'lover',
  'piece',
  'peace',
  'plane',
  'drink',
  'needs',
  'tours',
  'later',
  'being',
  'tokyo',
  'piano',
  'photo',
]
